.md-nav__link {
  padding: 0 0.6rem;
  color: var(--dark);
  line-height: 32px;
}

.md-nav__link::after {
  font-size: 16px;
  vertical-align: -0.25em;
}

.md-nav__toggle:checked + .md-nav__link,
.md-nav__link--active,
.md-nav__link:hover {
  color: var(--dark) !important;
  background-color: var(--light-blue) !important;
  border-radius: 8px;
  transition: background-color 300ms ease;
}

.md-nav__link--active {
  color: var(--blue) !important;
  text-shadow: 0px 0px 1px var(--blue), 0px 0px 1px var(--blue), 0px 0px 1px var(--blue);
}

.md-sidebar--primary {
  background-color: #FFFFFF;
}

.md-sidebar--secondary .md-nav__title {
  margin-bottom: 0.4rem;
  padding: 0.8rem 0.4rem 0.8rem;
  font-size: 12px;
  text-transform: uppercase;
}

.md-sidebar--secondary .md-sidebar__scrollwrap {
  height: initial !important;
}

.md-sidebar--secondary .md-nav__list {
  padding: 0 0rem 0rem 1.2rem;
}

.md-sidebar--secondary .md-sidebar__scrollwrap .md-nav__link {
  font-weight: 300;
}

.md-sidebar--secondary
.md-sidebar__scrollwrap
.md-nav__link[data-md-state="blur"]:not(.md-nav__link--active) {
  color: #666666;
}

.md-sidebar--secondary .md-sidebar__scrollwrap .md-nav__link:hover {
  color: var(--blue) !important;
  text-shadow: 0px 0px 1px var(--blue);
}

.md-sidebar--secondary .md-nav__link {
  margin-top: 0.225em;
  padding: 0.1rem 0.2rem;
}

.md-nav[data-md-level="0"] > .md-nav__list > .md-nav__item {
  padding: 0 !important;
}

.md-sidebar--secondary li {
  list-style-type: disc;
}

.md-search {
  display: flex;
  justify-content: center;
}

.md-search__inner {
  float: inherit;
}

.md-search__input {
  margin-bottom: 10px;
  background-color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

.md-search__input::placeholder {
  color: rgba(0, 0, 0, 0.07);
}

.md-search__icon {
  color: #000000 !important;
}

.md-header-nav__source {
  margin-left: 0;
}

/* stylelint-disable-next-line selector-class-pattern */
.repo_url {
  height: initial !important;
  margin-bottom: 0.4rem;
}

@media only screen and (min-width: 60em) {
  [data-md-toggle=search]:checked~.md-header .md-search__inner {
    margin-top: 100px;
  }
}

@include break-to-device(screen) {
  .md-search__inner {
    top: 64px;
    right: 0;
    margin-right: 0;
    margin-left: 0;
  }
}
