.md-footer-meta {
  background-color: var(--dark);
}

.md-footer-privacy-policy {
  margin: 0 0.6rem;
  padding: 0.4rem 0;
  color: hsla(0, 0%, 100%, 0.3);
  font-size: 0.64rem;
}
