:root {
  --dark: #06102A;
  --blue: #1E54D5;
  --light-blue: #EFF6FE;

  --input-bg-color: #FFFFFF;
  --input-color: #000000;
  --input-placeholder-color: #BBBBBB;
  --input-border-color: #DCDCDC;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1274px;
  margin: 0 auto;
  padding: 0 25px;
}
