/*

Atom One Light by Daniel Gamage
Original One Light Syntax theme from https://github.com/atom/one-light-syntax

base:    #FAFAFA
mono-1:  #383A42
mono-2:  #686B77
mono-3:  #A0A1A7
hue-1:   #0184BB
hue-2:   #4078F2
hue-3:   #A626A4
hue-4:   #50A14F
hue-5:   #E45649
hue-5-2: #C91243
hue-6:   #986801
hue-6-2: #C18401

*/

.hljs {
  display: block;
  padding: 0.5em;
  overflow-x: auto;
  color: #383A42;
  background: #FAFAFA;
}

.hljs-comment,
.hljs-quote {
  color: #A0A1A7;
  font-style: italic;
}

.hljs-doctag,
.hljs-keyword,
.hljs-formula {
  color: #A626A4;
}

.hljs-section,
.hljs-name,
.hljs-selector-tag,
.hljs-deletion,
.hljs-subst {
  color: #E45649;
}

.hljs-literal {
  color: #0184BB;
}

.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta-string {
  color: #50A14F;
}

/* stylelint-disable-next-line selector-class-pattern */
.hljs-built_in,
.hljs-class .hljs-title {
  color: #C18401;
}

.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-type,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-number {
  color: #986801;
}

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-title {
  color: #4078F2;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 700;
}

.hljs-link {
  text-decoration: underline;
}
